import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from '@mui/icons-material/Close';
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Divider } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from '@mui/material/Collapse';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Stack from '@mui/material/Stack';
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import TemporaryDrawer from 'Drawers/AdminDrawer.js';
import APIRequest from "Helpers/ApiRequest.js";
import ClearSession from "Helpers/ClearSession.js";
import theme from "Helpers/theme.js";
import conectadoIcon from "img/ConectadoIcon.png";
import { useSnackbar } from 'notistack';
import PropTypes from "prop-types";
import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, email, status) {
  return { name, email, status };
}


function encodeURI(details){
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
}


const defaultTheme = theme;
const fileTypes = ["JPG", "PNG"];
const cookies = new Cookies();

async function getData() {

  try{
   var newRows = [];
   var emails = [];
   var facultyBody = encodeURI({action: "admin_list_faculty"});
   var adminBody = encodeURI({action: "admin_list_admins"});

   const response = await Promise.all([
   fetch("https://conectadoverse.com/api/api.php", {method: "POST", headers: {"Content-Type": "application/x-www-form-urlencoded",},body: facultyBody,}),
   fetch("https://conectadoverse.com/api/api.php", {method: "POST", headers: {"Content-Type": "application/x-www-form-urlencoded",},body: adminBody,})
   ]);
   let facultyData = await response[0].json();
   let adminData = await response[1].json();

   if(adminData.status == false && facultyData.status == false){
    console.log("Failed");
    if(location.hostname !== "localhost"){
      ClearSession();
    }
   }

   if(adminData.status == true){
    adminData.data.admins.forEach(fac => {
      newRows.push(createData(`${fac.firstname} ${fac.lastname}`, fac.email, 1));
      emails.push(fac.email);
    });
  }
   if(facultyData.status == true){
     facultyData.data.faculty.forEach(fac => {
      if(!emails.includes(fac.email)){
       newRows.push(createData(`${fac.firstname} ${fac.lastname}`, fac.email, 0));
      }
       });
   }


 }
 catch(err){
   setError("error");
   setMessage(err);
   console.log(err);
 }
 finally{
   return newRows;
 }
   
}

async function getCurrentUsers() {
  try{
    var userCountTemp = "Error";
    var callBody = encodeURI({action: "get_server_sessions"});
    const response = await Promise.all([
    fetch("https://conectadoverse.com/api/api.php", {method: "POST", headers: {"Content-Type": "application/x-www-form-urlencoded",},body: callBody,}),
    ]);
    let callData = await response[0].json();
  
    if(callData.status == true){
      userCountTemp = callData.data.sessions.length;
   } 
  }
  catch(err){
    setError("error");
    setMessage(err);
    console.log(err);
  }
  finally{
    return userCountTemp;
  }
    
}

export default function Admin() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState("");
  const [userCount, setUserCount] = React.useState("0");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
  async function getNewRowsAsync() {
    const row = await getData();
    setRows(row.sort((a, b) => (b.status < a.status ? -1 : 1)));
  }

  async function getNewUserCountAsync() {
    const users = await getCurrentUsers();
    setUserCount(users);
  }

  React.useEffect(() => {
    async function getNewRows() {
      const row = await getData();
      setRows(row.sort((a, b) => (b.status < a.status ? -1 : 1)));
    }
    getNewRows();

    async function getNewUserCount() {
      const users = await getCurrentUsers();
      setUserCount(users);
    }
    getNewUserCount();

    let timer;
    timer = setInterval(() => {
      const sec = new Date().getSeconds();

      if(sec) return;
      getNewUserCount();
    }, 1000);

    return ()=>{
      clearInterval(timer);
    }

  }, [])

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [err, setError] = React.useState("");
  const [mess, setMessage] = React.useState("Unknown Error");
  const [searchParams, setSearchParams] = useSearchParams();
  const [facultyEmail, setFacultyEmail] = React.useState("");
  const [adminEmail, setAdminEmail] = React.useState("");
  const [validZoomLink, setValidZoomLink] = React.useState("");
  const [zoomLink, setZoomLink] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleOpen = (severity, message) => {
    const severities = ["error", "warning", "info", "success"];
    if (!severities.includes(severity)){
      severity = "warning";
    }
    enqueueSnackbar(message, { variant: severity , preventDuplicate: true});
  };

  const handleZoomChange = (e) => {
    const enteredLink = e.target.value;
    const zoomRegex = /zoom./;
    setZoomLink(enteredLink);
    setValidZoomLink(zoomRegex.test(enteredLink));
  };

  async function ZoomLinkChange() {
    var req = await APIRequest({action: "server_settings_set", settings_name:"zoom_link", settings_value:zoomLink});
    if (req.status == true) {
      handleOpen("success", req.message);

    }
    else{
      handleOpen("error", req.message);
    }

  }


  const [file, setFile] = React.useState(null);
  const handleChange = (file) => {
    setFile(file);
    console.log(file);
  };

  const areAllFieldsFilled = file != null;

  var loc = searchParams.get("plc") ? "/?plc=" + searchParams.get("plc") : "";

  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  const handleUserRemove = async (event, statusIn, emailIn) => {
    event.preventDefault();
    switch(statusIn){
      case 0:
        //Remove Faculty
        var body = await APIRequest({action: "admin_set_faculty", email: emailIn, value: 0});
      break;
  
      case 1:
        //Remove Admin
        var body = await APIRequest({action: "admin_set_faculty", email: emailIn, value: 0});
        var body2 = await APIRequest({action: "admin_set_admin", email: emailIn, value: 0});    
      break;
    }
    if (body.status == true) {
      handleOpen("success", body.message);
    }
    else{
      handleOpen("error", body.message);
    }
    getNewRowsAsync();
  }

  const handleUserPromoteDemote = async (event, statusIn, emailIn) => {
    event.preventDefault();
    switch(statusIn){
          case 0:
            //promote faculty to admin
            var body = await APIRequest({action: "admin_set_admin", email: emailIn, value: 1});
          break;
      
          case 1:
            //Demote admin to faculty
            var body = await APIRequest({action: "admin_set_faculty", email: emailIn, value: 1});
            var body2 = await APIRequest({action: "admin_set_admin", email: emailIn, value: 0});
          break;
        }
        if (body.status == true) {
          handleOpen("success", body.message);
        }
        else{
          handleOpen("error", body.message);
        }
        getNewRowsAsync();
  }


  const addUserType = async (event, action, email) => {
    event.preventDefault();
    var body = await APIRequest({action: action, email: email, value: 1});
    if (body.status == true) {
      handleOpen("success", "Added User");
    }
    else{
      handleOpen("error", body.message);
    }
    getNewRowsAsync();
  };
  return (
    <Box>
    <TemporaryDrawer/>
      <Container component="main" maxWidth="xm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
        
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "6rem",
              height: "6rem",
              marginBottom: "1rem",
              boxShadow: "5px 10px 20px #0000000F",
              borderRadius: "15px",
            }}
          >
            <div
              alt="Conectado"
              style={{
                backgroundImage: `url(${conectadoIcon})`,
                width: "4rem",
                height: "4rem",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Grid>
          <Typography component="h1" variant="h5">
            Admin
          </Typography>

              <Box
                component="form"
                id="loginForm"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1, width: "100%" }}
              >
                  <Stack direction="row" spacing={1} alignItems="center">
                <Typography component="h3" variant="h7">
                  Active Users: {userCount} 
                </Typography>
                <IconButton aria-label="delete"color="primary" onClick={getNewUserCountAsync}><RefreshIcon/></IconButton>
                </Stack>
                <Divider
                  sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                ></Divider>
                                            <Typography component="h3" variant="h7" sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                      Zoom
                    </Typography>
                    <Typography component="h3" variant="h6">
                      Change Zoom Link
                    </Typography>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="zoomLink"
                      label="Zoom Link"
                      name="zoomLink"
                      value={zoomLink}
                      onChange={(e) => handleZoomChange(e)}
                      autoComplete="link"
                      autoFocus
                      variant="filled"
                      InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 0}}}}
                    />
                                        <Button
                      type="adminSubmit"
                      fullWidth
                      variant="contained"
                      disabled={!validZoomLink}
                      endIcon={<ArrowForwardIcon />}
                      onClick={ZoomLinkChange}
                      sx={{ mt: 1, mb: 2 }}
                    >
                      Change Link
                    </Button>

                <Divider
                  sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                ></Divider>
                            <Typography component="h3" variant="h7" sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                      Add Management
                    </Typography>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginTop: "1rem" }}
                >
                  <Grid item xs>
                    <Typography component="h3" variant="h6">
                      Add Admin By Email
                    </Typography>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="adminEmail"
                      label="Admin Email Address"
                      name="adminEmail"
                      value={adminEmail}
                      onChange={(e) => setAdminEmail(e.target.value)}
                      autoComplete="email"
                      autoFocus
                      variant="filled"
                      InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 0}}}}
                    />
                    <Button
                      type="adminSubmit"
                      fullWidth
                      variant="contained"
                      disabled={!adminEmail.length > 0}
                      endIcon={<ArrowForwardIcon />}
                      onClick={(e) => addUserType(e, "admin_set_admin", adminEmail)}
                      sx={{ mt: 1, mb: 2 }}
                    >
                      Add Admin
                    </Button>
                  </Grid>
                  <Grid item xs sx={{ marginLeft: "1rem" }}>
                    <Typography component="h3" variant="h6">
                      Add Faculty By Email
                    </Typography>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="facultyEmail"
                      label="Faculty Email Address"
                      name="facultyEmail"
                      value={facultyEmail}
                      onChange={(e) => setFacultyEmail(e.target.value)}
                      autoComplete="email"
                      autoFocus
                      variant="filled"
                      InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 0}}}}
                    />{" "}
                    <Button
                      type="facultySubmit"
                      fullWidth
                      variant="contained"
                      disabled={!facultyEmail.length > 0}
                      endIcon={<ArrowForwardIcon />}
                      onClick={(e) => addUserType(e, "admin_set_faculty", facultyEmail)}
                      sx={{ mt: 1, mb: 2 }}
                    >
                      Add Faculty
                    </Button>
                  </Grid>
                </Grid>

            <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }}></Divider>
            <Typography component="h3" variant="h7" sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
                      Faculty/Admin Management
                    </Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 500 }}
                aria-label="Admin Table"
              >
              {rows &&
                <TableBody>
                  {(rowsPerPage > 0
                    ? rows.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : rows
                  ).map((row) => (
                    <TableRow key={row.email}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell style={{}} align="right">
                        {row.email}
                      </TableCell>
                      <TableCell style={{}} align="center">
                        {row.status ? "Admin" : "Faculty"}
                      </TableCell>
                      <TableCell style={{}} align="right">
                      <Typography
                        component={Button}
                        variant="contained"
                        color="text.primary"
                        sx={{ textDecoration: "none" }}
                        onClick={(e) => handleUserPromoteDemote(e, row.status ,row.email)}
                      >{row.status ? "Demote" : "Promote"}</Typography>
                      </TableCell>
                      <TableCell style={{}} align="right">
                      <Typography
                        component={Button}
                        variant="contained"
                        color="text.primary"
                        onClick={(e) => handleUserRemove(e, row.status ,row.email)}
                        sx={{ textDecoration: "none" }}
                      >X</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={3}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <Divider sx={{marginTop: "1rem", marginBottom: "1rem"}}></Divider>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{ color: "text.secondary", align: "center", fontWeight: 600 }}
            >
              Want to change the banner?{" "}
              {
                <Typography
                  component={Link}
                  to={`/mod${loc}`}
                  variant="contained"
                  color="primary"
                  sx={{ textDecoration: "none" }}
                >
                  Change Banner
                </Typography>
              }
            </Typography>
          </Box>
        </Box>
        <Button
                      type="logout"
                      fullWidth
                      variant="contained"
                      endIcon={<ArrowForwardIcon />}
                      onClick={ClearSession}
                      sx={{ mt: 1, mb: 2 }}
                    >
                      Logout
                    </Button>
      </Container>
      <Collapse in={!open}>
        <Snackbar open={err != "" && !open} autoHideDuration={6000}>
          <Alert severity={err == "" ? "error" : err} sx={{ width: "100%" }} action={            <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>}>
            {mess}
          </Alert>
          
        </Snackbar>
      </Collapse>
    </Box>
  );
}

import AppleIcon from '@mui/icons-material/Apple';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import StreamIcon from '@mui/icons-material/Stream';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import MainDrawer from "Drawers/MainDrawer.js";
import theme from "Helpers/theme.js";
import SignIn from "Pages/Login.js";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SpeedTester from 'browser-speed-test';

import { isChrome, isChromium, isEdge, isFirefox, isIE } from 'react-device-detect';


const defaultTheme = theme;

const cookies = new Cookies();

export default function Device({setDevice}) {
  const [deviceChosen, setDeviceChosen] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [downloadSpeed, setDownloadSpeed] = React.useState(0);
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const isWin = navigator.platform.toUpperCase().indexOf('WIN') >= 0;
  const foundOS = isMac || isWin;

  const plc = searchParams.get("plc")

  const toRender = deviceChosen || plc != null

  const remoteUrl = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/WP20Symbols_MediaWiki_light_background.svg/312px-WP20Symbols_MediaWiki_light_background.svg.png"

  const tester = new SpeedTester({ 
    url: remoteUrl,
    fileSize: 33334
  });


  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const [dialogTitle, setDialogTitle] = React.useState(isIE ? "Internet Explorer Unsupported" : "Browser Not Recommended");
  const [dialogBody, setDialogBody] = React.useState("Google Chrome has the best user experience for Conectado, would you like to download it?");
  const [dialogState, setDialogState] = React.useState("Browser");

  const minSpeed = 200;

  React.useEffect(() => {
    async function fetchData() {
      try {
        const data = await tester.start();
        setData(data);
        console.log(data)
        if (data < minSpeed && !open){
          setOpen(true);
          setDialogBody("Your experience may be affected. Conectado recommends a minimum internet speed of 20 Mbps for the best experience. If you are using Wifi, try using a wired connection.");
          setDialogTitle("Low Internet Connection Detected");
          setDialogState("Speed");
        }
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }


    fetchData();
  }, []);

  const [open, setOpen] = React.useState(!isChrome && !isFirefox && !isChromium && !isEdge);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const mainTheme = useTheme();

  return (
    <>
    {!toRender &&
      <Box theme={useTheme()}>
      <CssBaseline />
        <Container component="main" maxWidth="xs" >
          
              <MainDrawer/>
              <Box sx={{marginY: "1.5rem"}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 149.47" stroke={mainTheme.palette.action.active} fill={mainTheme.palette.text.primary}>
                  <path d="M60.87,37.93c31.72,0,47.58,20.61,49.76,36.27h-22c-9.78-25.89-61.94-21.96-60.07,15.46-1.71,36.78,50.15,44.79,60.67,17.05h22c-10.66,54.58-105,46.53-103.28-16.85,0-31.32,21.41-51.93,52.93-51.93Z"/>
                  <path d="M229.49,90.06c0-30.1-21.97-52.09-54.63-52.13h0s-.06,0-.09,0h0c-73.02-1.51-72.29,106.35,0,105.23h0s.06,0,.09,0h0c32.66-.05,54.63-22.82,54.63-53.11ZM174.84,123.16c-43.68,1.66-44.34-67.32,0-65.21,44.59-1.8,43.64,66.66,0,65.21Z"/>
                  <path d="M303.53,37.93c20.42,0,36.08,11.49,36.08,34.09v67.98h-20.82v-53.12c0-20.02-7.53-28.74-23.2-28.74-17.05,0-31.52,8.92-31.52,28.74v53.12h-20.81V40.9h20.81c.45,8.13-3.25,17.35-6.34,24.77l3.56,1.39c5.59-20.96,19.31-30.51,42.23-29.13Z"/>
                  <path d="M407.29,97.39h53.58c8.31-73.31-104.73-82.21-107.8-7.53-2.17,60.29,90.72,71.73,105.62,21.8h-23.2c-10.6,23.76-59.26,13.49-58.24-14.27h30.03ZM407.24,82.13h-29.79c1.11-35.1,60.32-35.95,59.62,0h-29.84Z"/>
                  <path d="M525.14,37.93c31.72,0,47.58,20.61,49.76,36.27h-22c-9.78-25.89-61.94-21.96-60.07,15.46-1.71,36.78,50.15,44.79,60.67,17.05h22c-10.66,54.58-105,46.53-103.28-16.85,0-31.32,21.41-51.93,52.93-51.93Z"/>
                  <path d="M606.01,5.86h20.82v35.28h25.77v18.63h-25.77v50.14c-1.54,13.46,15.64,10.11,24.78,10.51v19.62h-21.22c-17.05,0-24.38-14.67-24.38-30.13v-50.14h-19.83v-18.63h19.83V5.86Z"/>
                  <path d="M750.35,40.9c-.68,8.81,2.38,17.98,5.55,26.16l-3.76,1.19c-2.59-19.58-17.69-30.13-37.36-30.23h0c-68.81-1.99-67.65,106.5,0,105.05h0c19.94-.2,34.77-11.48,37.36-31.21l3.76,1.19c-3.28,8.36-6.24,17.94-5.55,26.95h20.82V40.9h-20.82ZM721.37,122.83l-6.6.71h0c-40.33.77-41.43-67.47,0-66.17h0c39.55-.07,40.57,56.15,6.6,65.46Z"/>
                  <path d="M871.06,5.86c.53,14.25-2.61,48.73,5.15,61.44l-3.77,1.39c-2.77-18.28-16.73-30.09-35.56-30.47v-.03c-67.8-3.68-69.74,109.25-.02,104.75v.03c18.77-.21,32.79-11.62,35.58-29.89l3.77,1.19c-3.36,8.08-6.02,17.09-5.15,25.76h20.81V5.86h-20.81ZM850.02,121.12l-13.14,2.37v-.03c-42.04,2.92-41.25-68.31-.02-66.02v.03l13.16,2.58c23.69,9.31,24.36,52.35,0,61.07Z"/>
                  <path d="M973.2,38.94l-11.74-1h0c-73.14-1.76-72.27,106.7,0,105.24h0c64.82,1.65,75.9-92.6,11.74-104.24ZM976.14,120.55l-14.67,2.61h0c-43.88,1.56-44.62-67.08,0-65.21h0s14.76,2.48,14.76,2.48c24.7,8.71,24.01,51.17-.09,60.13Z"/>
                </svg>
              </Box>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
              {(isWin || !foundOS) &&
              <Button variant="contained" href="./desktop?device=pc" fullWidth endIcon={<MicrosoftIcon />} sx={{height:"80px", marginBottom:"1rem"}}>Windows</Button>
            }
            {(isMac || !foundOS) &&
              <Button variant="contained" href="./desktop?device=mac" fullWidth endIcon={<AppleIcon />} sx={{height:"80px", marginBottom:"1rem"}}>Apple</Button>
            }
              <Button variant="contained" fullWidth onClick={() => {setDeviceChosen(true)}} endIcon={<StreamIcon />} sx={{height:"80px", marginBottom:"1rem"}}>Web</Button>
              <Button variant="contained" href="./register" endIcon={<HowToRegIcon />} sx={{height:"40px", marginBottom:"1rem", width: "50%", marginTop:"0.5rem"}}>Register</Button>
              </Stack>
          </Container>
        </Box>
    }
    {toRender &&
    <>

    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.error.main,
          },
        }}
        
      >
        <DialogTitle id="alert-dialog-title" sx={{color: "#fff"}}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description" sx={{color: "#fff"}}>
            {dialogBody}
          </Typography>
        </DialogContent>
        <DialogActions>
        
          <Button onClick={handleClose} sx={{color: "#fff"}}>Continue</Button>
          {dialogState === "Browser" &&
          <Button onClick={handleClose} sx={{color: "#fff"}} href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer" autoFocus>Download</Button>
          }
        </DialogActions>
      </Dialog>
    <SignIn/>
    </>
    }
    
    </>
  );
}



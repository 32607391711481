import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import TemporaryDrawer from 'Drawers/AdminDrawer';
import theme from 'Helpers/theme';
import conectadoIcon from "img/ConectadoIcon.png";
import * as React from 'react';
import { FileUploader } from "react-drag-drop-files";
import { Link, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

const defaultTheme = theme;
const fileTypes = ["PNG"];
const cookies = new Cookies();



export default function Mod() {
  const [err, setError] = React.useState("");
  const [mess, setMessage] = React.useState("Unknown Error");
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(false);
  };

  function Banner(props){
    const [upFile, setFile] = React.useState(null);
    const areAllFieldsFilled = (upFile != null && upFile?.size < 5242880)
    const [img, setImg] = React.useState("");

    const handleChange = (file) => {
      setFile(file);
      setImg(URL.createObjectURL(file));
      if(file.size > 5242880){
        handleOpen();
        setError("error");
        setMessage("File Too Large");
      }
      console.log(upFile)
    };

    const handleSubmit = (props) => async (event) => {
      event.preventDefault();
      let formData = new FormData();
      formData.append("action", "admin_upload_banner");
      formData.append(props.bannerName, upFile);
      
      // var req = await APIRequest({action: "admin_upload_banner", banner:file});

      var response = await fetch("https://conectadoverse.com/api/api.php", {
        method: "POST",
        headers: {
          
        },
        body: formData
        
      });
      console.log(formData);
      var req = await response.json();



      if(req.status == true){
        handleOpen();
        setError("success");
        setMessage(req.message);
      }
      else{
        handleOpen();
        setError("error");
        setMessage(req.message);
      }
    };

  const resetBanner = (props) => async (event) => {
    event.preventDefault();
    var details = {
      action: "admin_reset_banner",
      "banner" : props.bannerName,
    };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    var response = await fetch("https://conectadoverse.com/api/api.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    });
    var body = await response.json();
    if (body.status == true) {
      handleOpen();
      setError("success");
        setMessage("Reset Banner");
    }
    else{
      handleOpen();
      setError("error");
      setMessage(body.message);
    }
  };
  

    return (
      <Grid item>
        <Card sx={{marginBottom: 1}}>
          <Typography gutterBottom variant="h5" sx={{textAlign:"center", mt:1}}>{props.bannerNameDisplay}</Typography>
          <Typography gutterBottom component="h3" variant="h6" sx={{fontSize: ".7rem", textAlign: "center"}}>Recommended Size: {props.resolution}px</Typography>
          <CardMedia
            sx={{ height: 140 }}
            image={(`${process.env.PUBLIC_URL}/${props.bannerName}.png`)}
            title={props.bannerName}
          />

          <CardContent sx={{paddingBottom:"0"}}>
            <Grid alignItems="center" justifyContent="center" sx={{marginTop:"0.5rem"}}>
                <FileUploader handleChange={handleChange} name={props.bannerName} types={fileTypes} required={true} />
            </Grid>
            <CardActions>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit(props)}
                    disabled={!areAllFieldsFilled}
                    endIcon={<ArrowForwardIcon />}
                    sx={{ mt: 1}}
                    >
                    Upload
                  </Button>
                  </Grid>
                  <Grid item alignItems="center" justifyContent="center" sx={{marginLeft:"1rem"}}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={resetBanner(props)}
                    endIcon={<RestartAltIcon />}
                    sx={{ mt: 1}}
                    >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    );
  
  }


  var loc = searchParams.get("plc") ? "/?plc=" + searchParams.get("plc") : ""



  return (
    <Box theme={defaultTheme}>
      <TemporaryDrawer/>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width:"100%",
          }}
        >
          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{width: "6rem", height: "6rem", marginBottom:"1rem", boxShadow: '5px 10px 20px #0000000F', borderRadius: "15px"}}>
            <div alt="Conectado" style={{backgroundImage: `url(${conectadoIcon})`, width: "4rem", height: "4rem", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat"}}></div>
          </Grid>
          <Typography component="h1" variant="h5" sx={{marginBottom: "0.2rem"}}>
            Upload New Banners
          </Typography>
          <Box component="form" id="bannerForm" noValidate sx={{ mt: 1, width:"100%"}}>
          <Typography component="h3" variant="h6" sx={{fontSize: ".7rem", textAlign: "center", marginBottom: 2}} >Max Size: 5MB</Typography>
          <Grid container spacing={1} direction="row" alignItems="center" justifyContent="center" sx={{width:"100%"}}>
            <Banner bannerName="banner1" bannerNameDisplay="Banner 1" resolution="1920x1080"/>
            <Banner bannerName="banner2" bannerNameDisplay="Banner 2" resolution="1920x1080"/>
            <Banner bannerName="banner3" bannerNameDisplay="Banner 3" resolution="1920x1080"/>
            <Banner bannerName="banner4" bannerNameDisplay="Banner 4" resolution="1000x3000"/>
          </Grid>
            <Typography variant="body2" textAlign="center" sx={{color: "text.secondary", align: "center", fontWeight:600}} >Want to go to admin? {<Typography component={Link} to={`/admin${loc}`} variant="contained" color="primary" sx={{textDecoration: 'none'}}>
                Admin
              </Typography>}
            </Typography>
          </Box>
        </Box>
      </Container>

      <Collapse in={!open}>
        <Snackbar open={err != "" && !open} autoHideDuration={6000}>
          <Alert severity={err == "" ? "error" : err} sx={{ width: "100%" }} action={            <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>}>
            {mess}
          </Alert>
          
        </Snackbar>
      </Collapse>

    </Box>
  );
}



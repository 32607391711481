import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';


import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Admin from '../Pages/Admin';
import Desktop from '../Pages/Desktop';
import Device from '../Pages/Device';
import Forgot from '../Pages/Forgot';
import SignIn from '../Pages/Login';
import Mod from '../Pages/Mod';
import Quiz from '../Pages/Quiz';
import Results from '../Pages/Results';
import SignUp from '../Pages/SignUp';
import AppStatic from './AppStatic';
import BaseApp from './BaseApp';
  

export default function App(props) {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
          createTheme({
            palette: {
              mode: prefersDarkMode ? 'dark' : 'light',
              primary: {
                light: '#94FFE8',
                main: '#26B99A',
                dark: '#008367'
              },
              secondary: {
                light: '#ffb2ba',
                main: '#F15162',
                dark: '#9b0024'
              },
              dark:{
                main: '#0D1A35',
              },
              text: {
                secondary: '#A1A2A5',
                hint: '#26b99a',
              },
            },
          
            
          
            typography: {
              fontFamily: 'Montserrat',
              h5: {
                fontWeight: 600,
              },
              h6: {
                fontWeight: 600,
                fontSize: 16,
                color: "#A1A2A5",
              },
              button: {
                fontWeight: 1000,
              },
              option:{
                fontWeight: 600,
                fontSize: 14
              }
            },
            components: {
              MuiButton: {
                styleOverrides: {
                  // Name of the slot
                  root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'contained' && {
                      fontSize: '1rem',
                      outline: 'none',
                      borderRadius: '40px',
                      background: 'linear-gradient(45deg, #94FFE8 -50%, #26B99A 100%)',
                      boxShadow: '3px 3px 15px #24415D4D',
                      '&:hover': {
                        boxShadow: '3px 3px 15px #24415D4D',
                      }
                      }),
                  }),
                }
            },
            button: {
            },
            MuiLink:{
              variants: [
                {
                  props: { variant: 'dashed' },
                    style: {
                      textTransform: 'none',
                      border: `2px dashed `,
                    },
                  }
              ]
            },
            MuiInputBase:{
              styleOverrides: {
                root:{
                  borderBottom: 'none',
                  disableUnderline: true,
                  boxShadow: '5px 5px 10px #00000005'
                }
          
              }
            },
            
            
            MuiTextField:{
              styleOverrides: {
                root:{
                  marginTop: 0,
                  borderRadius: '40px',
                }
              }
            },
          
            MuiFormControlLabel:{
              styleOverrides: {
                root:{
                  fontWeight: 600
                }
              }
            },
            MuiCardContent:{
              styleOverrides: {
                root:{
                  "&:last-child": {
                    paddingBottom: 5,
                  }
                }
              }
            }
        }
          }),
        [prefersDarkMode],
      );


    const router = createBrowserRouter([
        {
          path: "/",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<Device/>} width="30rem"/>,
        },
        {
          path: "/device",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<Device/>} width="30rem"/>,
        },
        {
          path: "/login",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<SignIn/>} width="30rem"/>,
        },
        {
          path: "/register",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<SignUp/>} width="30rem"/>,
        },
        {
          path: "/login",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<SignIn/>} width="30rem"/>,
        },
        {
          path: "/forgot",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<Forgot/>} width="30rem"/>,
        },
        {
          path: "/desktop",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<Desktop/>} width="80rem"/>,
        },
        {
          path: "/static",
          element: <AppStatic />,
        },
        {
          path: "/results",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<Results/>} width="90vw"/>,
        },
        {
          path: "/admin",
          element: <BaseApp redirectURL="" isAdmin={true} Child={<Admin/>} width="80rem"/>,
        },
        {
          path: "/mod",
          element: <BaseApp redirectURL="mod" isAdmin={true} Child={<Mod/>} width="80rem"/>,
        },
        {
          path: "/quiz",
          element: <BaseApp redirectURL="quiz" isAdmin={true} Child={<Quiz/>} width="80rem"/>,
        },
      
        {
          path: "/*",
          element: <BaseApp redirectURL="" isAdmin={false} Child={<SignIn/>} width="30rem"/>,
        },
      ]);

    const notistackRef = React.useRef();
    const onClickDismiss = key => () => { 
      notistackRef.current.closeSnackbar(key);
    }
      

  return (
    <ThemeProvider theme={theme}>
      <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" rel="stylesheet" />
      <SnackbarProvider autoHideDuration={5000} ref={notistackRef} action={(key) => (
        <IconButton onClick={onClickDismiss(key)}><CloseIcon fontSize="inherit" /></IconButton>)}>
        <CssBaseline />
        <RouterProvider router={router} />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

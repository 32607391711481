import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Copyright from 'Helpers/Copyright';
import useRedirect from 'Helpers/useRedirect';
import useToken from 'Helpers/useToken';
import background from "img/Background.png";
import AdminLogin from 'Pages/AdminLogin';
import PropTypes from 'prop-types';

export default function BaseApp(props) {
  const { token, setToken } = useToken();
  const { redirect, setRedirect } = useRedirect();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  if (props.isAdmin == true){
    if(!token) {
      return <AdminLogin setToken={setToken} setRedirect={setRedirect} />
    }
  
    if(redirect) {
      localStorage.removeItem("redirect");
      window.location.replace(redirect);
    }
  }

  return (
    <Box>
      <Box sx={{ 
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: '100vh',
      margin: 0,
      position: 'fixed',
      top: 0,
      left: 0,
      ...(!matches && {backgroundImage: 'none', backgroundColor: 'background.paper'})
      }}
      >
        
      </Box>
        <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        position="sticky"
        sx={{display: "flex", height: "100vh"}}
        >
          <Box sx={{ my: 'auto', padding: "20px" , backgroundColor: 'background.paper', borderRadius: '25px', width:props.width, boxShadow: '5px 5px 50px #0000004D', 
          ...(!matches && {height: "100%", width:"100%", borderRadius:"0px"}) }}>
            {props.Child}
            <Copyright />
          </Box>
        </Grid>
    </Box>
  );

  

}

BaseApp.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    Child: PropTypes.object
}
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider } from '@mui/material';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MainDrawer from 'Drawers/MainDrawer';
import theme from 'Helpers/theme';
import conectadoIcon from "img/ConectadoIcon.png";
import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import AppIcon from "Helpers/AppIcon";

const defaultTheme = theme;

const cookies = new Cookies();

export default function SignUp() {
  const [err, setErrorSignup] = React.useState("");
  const [mess, setMessageSignup] = React.useState("Unknown Error");
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const areAllFieldsFilled = (email != "") && (password != "") && (lastname != "") && (firstname != "")

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };


  var loc = searchParams.get("plc") ? "/?plc=" + searchParams.get("plc") : ""
  var plc2 = "";
  if (searchParams.get("plc")) {
    plc2 = searchParams.get("plc");
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    //const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')
    var plc = "";
    if (searchParams.get("plc")){
      plc = searchParams.get("plc");
    }
    var details = {
      'action': 'register',
      'firstname': data.get('firstname'),
      'lastname': data.get('lastname'),
      'email': data.get('email'),
      'password': data.get('password')
    }
    if (plc.length > 0){
      details = {
        'action': 'register',
        'firstname': data.get('firstname'),
        'lastname': data.get('lastname'),
        'email': data.get('email'),
        'password': data.get('password'),
        'external_login': searchParams.get("plc")
      }
    }

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    var response = await fetch('https://conectadoverse.com/api/api.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formBody
  })
  var body = await response.json();
  if (body.status == true){
    var userID = body.data.account_id;
    var cookieID = body.data.cookie_id;
    var firstName = data.get('firstname');
    var lastName = data.get('lastname');
      cookies.set('user', userID, { path: '/' });
      cookies.set('cookie', cookieID, { path: '/' });
      cookies.set('firstname', firstName, { path: '/' });
      cookies.set('lastname', lastName, { path: '/' });

      if (plc){
        if(plc.length > 0){
          handleOpen();
          setError("success");
          setMessage("Registration Successful, Continue On Client");
        }
        else{
          handleOpen();
          setError("success");
          setMessage("Registration Successful, Sending To Conectado");
          window.location.replace("https://conectadoverse.com/")
        }
      }
      else{
        handleOpen();
        setErrorSignup("success");
        setMessageSignup("Registration Successful");
        window.location.replace("https://conectadoverse.com/")
      }
    
  }
  else{
    setErrorSignup("error");
    setMessageSignup(body.message);
  }
  };

  return (
    <Box>
    <MainDrawer/>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AppIcon />
          <Typography component="h1" variant="h5" sx={{marginBottom: "1rem"}}>
            Register for Conectado
          </Typography>
          <Box component="form" id="loginForm" onSubmit={handleSubmit}  noValidate sx={{ mt: 1, width:"100%"}}>
          <Typography component="h3" variant="h6" >Email</Typography>
            <TextField
              margin="none"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={ e => setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
              variant="filled"
              InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 0}}}}
              
            />
          <Typography component="h3" variant="h6" sx={{marginTop:"1rem"}}>Password</Typography>
            <TextField
              margin="none"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={ e => setPassword(e.target.value)}
              autoComplete="current-password"
              variant="filled"
              InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 0}}}}
            />

            <Grid container alignItems="center" justifyContent="center" sx={{}}>
              <Grid item xs>
              <Typography component="h3" variant="h6" sx={{marginTop:"1rem"}}>First Name</Typography>
            <TextField
              margin="none"
              required
              fullWidth
              name="firstname"
              label="First Name"
              id="firstname"
              value={firstname}
              onChange={ e => setFirstname(e.target.value)}
              autoComplete="current-firstname"
              variant="filled"
              InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 0}}}}
            />

              </Grid>
              <Grid item xs sx={{marginLeft:"1rem"}}>
              <Typography component="h3" variant="h6" sx={{marginTop:"1rem"}}>Last Name</Typography>
            <TextField
              margin="none"
              required
              fullWidth
              name="lastname"
              label="Last Name"
              id="lastname"
              value={lastname}
              onChange={ e => setLastname(e.target.value)}
              autoComplete="current-lastname"
              variant="filled"
              InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 0}}}}
            />

              </Grid>
            </Grid>

            <Divider sx={{marginTop: "1rem"}}></Divider>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!areAllFieldsFilled}
              endIcon={<ArrowForwardIcon />}
              sx={{ mt: 3, mb: 2}}
              >
                
              Register
            </Button>
            <Typography variant="body2" textAlign="center" sx={{color: "text.secondary", align: "center", fontWeight:600 }} >Already have an account? {
              <Typography component={Link} to={`/login${loc}`} variant="contained" color="primary" sx={{textDecoration: 'none'}}>
                Login
              </Typography>
          }
            </Typography>
          </Box>
        </Box>
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={err != "" && open}
        onClick={handleClose}
      >
        <Box
        sx={{
          width: 500,
          height: 700,
          borderRadius: 8,
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderTop: 220,
          borderColor: 'background.paper',
        }}
      >
          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{width: "6rem", height: "6rem", marginBottom:"1rem", boxShadow: '5px 10px 20px #0000000F', borderRadius: "15px"}}>
            <div alt="Conectado" style={{backgroundImage: `url(${conectadoIcon})`, width: "4rem", height: "4rem", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat"}}></div>
          </Grid>
          <Typography component="h1" variant="h4" fontWeight={800} sx={{marginBottom: ".5rem", color: "dark.main"}}>
          Welcome {cookies.get('firstname')}
          </Typography>{plc2.length > 0 &&
          <Typography component="h1" variant="h5" fontWeight={500} sx={{marginBottom: "1rem", color: "dark.main"}}>
          Please Continue On Client
          </Typography>}
      </Box>

      <Snackbar open={err != ""} autoHideDuration={6000}>
        
      <Alert severity={err == "" ? "error": err} sx={{ width: '100%' }}>
        {mess}
      </Alert>
    </Snackbar>
    </Backdrop>

    <Snackbar open={err != "" && !open} autoHideDuration={6000}>
        
        <Alert severity={err == "" ? "error": err} sx={{ width: '100%' }}>
          {mess}
        </Alert>
      </Snackbar>
    </Box>
  );
}

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Divider, Tooltip } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import { tooltipClasses } from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import MainDrawer from "Drawers/MainDrawer.js";
import APIRequest from "Helpers/ApiRequest.js";
import AppIcon from "Helpers/AppIcon";
import { useSnackbar } from 'notistack';
import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";
import VirtualizedList from "Helpers/VirtList";
import { ScopeMap } from "Helpers/Scopes";



const cookies = new Cookies();

function Capitalize(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function SignIn() {
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [remember, setRemember] = React.useState(false);
  const [requestedActions, setRequestedActions] = React.useState();
  const areAllFieldsFilled = email != "" && password != "";
  const [open, setOpen] = React.useState(false);
  const desktopTooltip = "Download a Desktop Version of the app"
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [oAuthValid, setOAuthValid] = React.useState(false);
  const [oAuthOpen, setOAuthOpen] = React.useState(false);
  const [oAuthDetails, setOAuthDetails] = React.useState({name: "", email: "", website:""});
  const scopeMap = ScopeMap;

  React.useEffect(() => {
    if (localStorage.getItem("remember")){
      setRemember(localStorage.getItem("remember") === "true" ? true : false);
    }
    const handleOAuth = async () => {
      if (params["oauth2"] == 1 && params["client_id"] && params["scope"] && params["state"] && params["response_type"]){
        var oAuthReq = await APIRequest({action: "oauth2_get_client_info", oauth2_client_id: params["client_id"], oauth2_scope: params["scope"], oauth2_state: params["state"], oauth2_response_type: params["response_type"], ...(params["redirect_uri"] ? {oauth2_redirect_uri: params["redirect_uri"]} : {})})
        if(oAuthReq.status == true){
          setOAuthDetails({name: oAuthReq.data.name, email: oAuthReq.data.contact_email, website:oAuthReq.data.website});
          var scopes = params["scope"].split(" ");
          var scopesList = [];
          scopes.forEach((x) => scopeMap[x] ? scopesList.push({title:Capitalize(x), data:scopeMap[x].data, icon:scopeMap[x].icon}) : scopesList.push({title:Capitalize(x), data:"", icon:"star"}))
          setRequestedActions(scopesList);
          setOAuthValid(true);
          handleOAuthOpen();
        }
        else{
          enqueueSnackbar(oAuthReq.message, { variant: "error" , preventDuplicate: true});
          setOAuthValid(false);
          handleOAuthClose();
        }
      }
    }
    handleOAuth();
    if (localStorage.getItem("remember") === "true"){
      if (localStorage.getItem("email")){
        setEmail(localStorage.getItem("email"));
      }
    }
  }, []);

  const handleOAuthOpen = () => {
    setOAuthOpen(true);
  };

  const handleOAuthClose = () => {
    setOAuthOpen(false);
  };


  const handlePlc = async () => {
    var req = await APIRequest({action: "is_external_login_complete", cookie_id:params["plc"]});
    if ((req.status == true) && params["plc"] != "test") {
      window.location.replace(window.location.origin+"/login");
    }
  };


  React.useEffect(() => {
    if (remember){
      localStorage.setItem("email", email);
    }
  }, [email]);

  const handleClose = () => {
    setOpen(false);
  };
  const OpenBackground = () => {
    setOpen(true);
  };

  const params = Object.fromEntries([...searchParams]);
  var loc = window.location.search;

  const handleRemember = (event) => {
    setRemember(event.target.checked);
    localStorage.setItem("remember", event.target.checked);
  }

  if (params["plc"] && !open){
    handlePlc();
  }



  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var body = await APIRequest({action:"login", email: data.get("email"), password: data.get("password"), ...(params["plc"]  ? {external_login:params["plc"]}:{}), ...(oAuthValid  ? {oauth2_client_id: params["client_id"], oauth2_scope: params["scope"], oauth2_state: params["state"], oauth2_response_type: params["response_type"]}:{}), ...(params["redirect_uri"] ? {oauth2_redirect_uri: params["redirect_uri"]} : {})});

    if (body.status == true) {
      var userID = body.data.account_id;
      var cookieID = body.data.cookie_id;
      var firstName = body.data.firstname;
      var lastName = body.data.lastname;
      cookies.set("user", userID, { path: "/", secure: true, sameSite: 'Lax'});
      cookies.set("cookie", cookieID, { path: "/", secure: true, sameSite: 'Lax'});
      cookies.set("firstname", firstName, { path: "/", secure: true, sameSite: 'Lax'});
      cookies.set("lastname", lastName, { path: "/", secure: true, sameSite: 'Lax'});
      if (body.data.auth_code && body.data.redirect_uri){
        enqueueSnackbar(`Successfully Linked Account`, { variant: "success"});
        
        window.location.replace(body.data.redirect_uri);
      }
      else{
        enqueueSnackbar(`Sign-in Successful${params["plc"] ? (params["plc"].length > 0 ? ", Continue On Client": ", Sending To Conectado"): ""}`, { variant: "success"});
        if (params["plc"]?.length > 0){
          setOpen(true);
        }
        else{
          window.location.replace("https://conectadoverse.com/stream/"+encodeURI(cookieID));
        }
      }
    } 
    else {
      enqueueSnackbar(body.message, { variant: "error" , preventDuplicate: true});
    }
  };



  return (
    <Box>
    <MainDrawer/>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          backgroundColor="background.paper"
        >
          <AppIcon height="5rem" width="5rem"/>

            <Typography component="h1" variant="h5" color="inherit" sx={{ textAlign:"center", ...(oAuthDetails?.website ? {marginBottom: "0px"}: {marginBottom: "1rem"})}}>
              {oAuthDetails?.website ? `Authori${(navigator?.language || navigator?.userLanguage) === "en-GB" ? "s" : "z"}ation Request`: "Sign in to Conectado"}
              {/* oAuthDetails?.website.replace(oAuthDetails?.website.split(".").at(-1), "").slice(0, -1).replace("https://","") */}
            </Typography>

            {oAuthDetails?.name &&
            <Tooltip arrow describeChild title={oAuthDetails?.email &&
              <React.Fragment>
                <Typography color="inherit" sx={{fontWeight: 700, fontSize: 12}}>Contact</Typography>
                <Typography component={Link} to={`mailto:`+ oAuthDetails?.email} target="_blank" rel='noopener noreferrer' color="inherit" sx={{textDecoration: 'none', fontSize: 14}}>{oAuthDetails?.email}</Typography>
              </React.Fragment>
            }>
          
            <Typography component={oAuthDetails?.website ? Link : "h1"} to={oAuthDetails?.website} variant="subtitle2" target="_blank" rel='noopener noreferrer' sx={{ textAlign:"center", textDecoration: 'none', fontSize: "11px", color: "text.secondary", textAlign:"center", marginBottom:"1rem"}}>{oAuthDetails?.name} is requesting access to your Conectado account</Typography>
          </Tooltip>
          }

          {oAuthValid &&
            <VirtualizedList width={"100%"} height={200} itemSize={80} items={requestedActions}/>
          }
          
          <Box
            component="form"
            id="loginForm"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <Typography component="h3" variant="h6" sx={{ marginTop: "1rem" }}>
              Email
            </Typography>
            
            <TextField
              margin="none"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
              variant="filled"
              InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 'inherit', WebkitTextFillColor: '#000'}, '& .MuiInputBase-root': {backgroundColor: 'background.paper'}
            }}}
            />
            <Typography component="h3" variant="h6" sx={{ marginTop: "1rem" }}>
              Password
            </Typography>
            <TextField
              margin="none"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="password"
              variant="filled"
              InputProps={{disableUnderline: true, sx: { borderRadius: "15px", '& input': {paddingLeft:2}, '& .MuiFilledInput-input:autofill': {boxShadow: 0, borderRadius: 'inherit', WebkitTextFillColor: '#000'}, '& .MuiInputBase-root': {backgroundColor: 'background.paper'}
            }}}
              
              
            />

            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: "1rem" }}
            >
              <Grid item xs>
                <FormControlLabel
                  control={<Checkbox checked={remember} color="primary" onChange={handleRemember}/>}
                  label={<Typography variant="option">Remember me</Typography>}
                />
              </Grid>
              <Grid item>
              <Tooltip title={desktopTooltip}>
                <Typography component={Link} to="/desktop" variant="option" sx={{textDecoration: 'none', fontWeight: 600, color: "primary.main"}}>
                  Desktop Version
                </Typography>
              </Tooltip>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "1rem" }}></Divider>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!areAllFieldsFilled}
              endIcon={<ArrowForwardIcon />}
              sx={{ mt: 3, mb: 2 }}
            >
              {oAuthOpen ? "Sign In and Authorize" : "Sign In"}
            </Button>
            <Typography
              variant="body2"
              textAlign="center"
              sx={{ color: "text.secondary", align: "center", fontWeight: 600 }}
            >
              Don't have an account?{" "}
              {
                <Typography
                  component={Link}
                  to={`/register${loc}`}
                  variant="contained"
                  color="primary"
                  sx={{ textDecoration: "none" }}
                >
                  Register
                </Typography>
              }
            </Typography>
          </Box>
        </Box>
        {email=="meet" &&
        <Typography component={Link} to="/meet" variant="option" sx={{textDecoration: 'none', fontWeight: 600, color: "primary.main"}}>
                  Temp Testing
                </Typography>}
      </Container>
      <Backdrop
        sx={{backgroundColor:'background.paper', color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Box
          sx={{
            width: 500,
            height: 700,
            borderRadius: 8,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderTop: 220,
            borderColor: "background.paper",
          }}
        >
          <AppIcon />
          <Typography
            component="h1"
            variant="h4"
            fontWeight={800}
            sx={{ marginBottom: ".5rem"}}
          >
            Welcome {cookies.get("firstname")}
          </Typography>
          {params["plc"] != null &&
          <Typography
            component="h1"
            variant="h5"
            fontWeight={500}
            sx={{ marginBottom: "1rem" }}
          >
            Please Continue On Client
          </Typography>
          }
        </Box>
      </Backdrop>
    </Box>
  );
}
